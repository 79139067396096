var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.players),function(player,index){return _c('v-card',{key:index,class:[
      _vm.isPlayer(player.id) ? 'whiteBorder' : '',
      'pa-2 mb-4 playerCard white--text' ],attrs:{"rounded":"pill"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{attrs:{"size":"40","color":"transparent"}},[_c('img',{attrs:{"src":player.avatarURL}})]),_c('v-card-title',{staticClass:"py-0 text-ellipsis-item"},[_c('div',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(player.username))])]),_c('v-spacer'),(_vm.answers)?_c('v-chip',{staticClass:"px-6 font-weight-bold",attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.playerAnswer(player.id)))]):_vm._e(),(_vm.scores && _vm.showPoints)?_c('div',{staticClass:"ml-3 fit-content"},[_c('span',{class:[
            index === 0
              ? 'gold'
              : index === 1
              ? 'silver'
              : index === 2
              ? 'bronze'
              : '',
            'px-2 mr-1 font-weight-bold' ]},[_vm._v(_vm._s(_vm.playerScore(player.id))+" "+_vm._s(_vm.$t("players.points")))])]):_c('div',[(_vm.isPlayerLeader(player.id) && !_vm.answers)?_c('div',{staticClass:"text-h5 mr-2 d-flex align-center"},[_c('img',{attrs:{"height":"30px","src":require("@/assets/icons/crownIcon.svg")}})]):(_vm.isLeader && !_vm.answers)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","x-small":"","fab":"","dark":""},on:{"click":function($event){return _vm.kickPlayer(player)}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e()],1)],1)])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }